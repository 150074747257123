<template xmlns:v-resize="http://www.w3.org/1999/xhtml">
    <div
            id="mapview"
            v-resize:debounce="resizeMap"
            class="content-container"
            @contextmenu="openMenu"
    >
        <div
                id="map-canvas"
                ref="map-canvas"
                class="map-style"
                @touchstart="touchmove"
                @mousemove="mousemove"
        />
        <l-map
                ref="lMap"
                v-if="showMap"
                :zoom="zoom"
                :center="mapCenter"
                :options="mapOptions"
                :minZoom="5"
                :maxZoom="20"
                class="map-style"
                @overlayadd="overlayAdd"
                @overlayremove="overlayRemove"
                @baselayerchange="changeBaseLayer"
         >
            <!--
               <l-wms-tile-layer
                        v-for="layer in layers"
                        :key="layer.name"
                        :base-url="baseUrl"
                        :layers="layer.layers"
                        :visible="layer.visible"
                        :name="layer.name"
                        :format="layer.format"
                        :layer-type="layer.type"
                        :attribution="attribution"
                        :minZoom="1"
                        :maxZoom="18"
                        :opacity="layer.opacity? layer.opacity : 1"
                        :noWrap="true"
                        :transparent="layer.transparent"
                        @ready="layerReady"
               />
               -->
                <l-control-zoom v-if="zoomVisible" position="bottomright"/>
            <!--
                <l-control-layers  v-if="layerControlVisible" position="bottomleft" style="text-align: left !important;"/>
                -->
                <div
                    ref="userposControl"
                    class="leaflet-right leaflet-bottom leaflet-control"
                    style="bottom: 80px; font-size: 18px">
                    <div
                        class="leaflet-touch leaflet-bar leaflet-control"
                    >
                        <a @click="watchUserPosition" :class="{'bg-primary color-white': watchUserPositionId && !isLoadingPosition}" style="width: 30px; height: 30px;">
                            <font-awesome-icon icon="crosshairs" :class="{pulse: isLoadingPosition}" />
                        </a>
                        <a
                            v-if="watchUserPositionId && !isLoadingPosition"
                            @click="clearWatchUserPosition"
                            style="width: 30px; height: 30px;"
                        >
                            <font-awesome-icon icon="times"/>
                        </a>
                    </div>
                </div>
        </l-map>
        <div
            ref="sliderControl"
            class="opacity-slider-container"
        >
            <vue-slider
                v-model="opacityValue"
                :tooltip="'none'"
                :min=minOpacity
                :max=maxOpacity
                :lazy="true"/>
        </div>
        <transition name="fade">
        </transition>
        <transition name="fade">
            <div
                    v-if="showInfo"
                    class="map-info"
                    :class="{top : infoOnTop}"
            >
                <map-info-table
                        class="map-info-content"
                        :fields="infoFields"
                        :data="infoContent"
                        :onTop="infoOnTop"
                        @click="hideMapInfoCallback"
                />
            </div>
        </transition>
        <b-button-group vertical
                        v-if="contextMenuVisible"
                        id="right-click-menu"
                        :style="{top: topMenu, left: leftMenu}"
                        tabindex="-1"
                        @blur="hideContextMenu">
            <b-button
                size="sm"
                class="popup-menu-item"
                @click.stop="copyCoordinates">{{ $t('map.copy_coordinates') }}
            </b-button>
            <div
                v-for="(item, index) in menuItems.filter(x => x != null)"
                :key="index"
                class="nopads">
                <b-dropdown
                    v-if="item.subItems"
                    id="index"
                    size="sm"
                    no-caret
                    dropright
                    class="popup-menu-item"
                    :text="item.text"
                    >
                    <template #button-content>
                        <div class="popup-menu-item">{{ item.text }}</div>
                    </template>
                    <b-dropdown-item
                        v-for="subItem in item.subItems"
                        :key="subItem.text"
                        size="sm"
                        variant="dark"
                        class="popup-sub-menu-item nopads"
                        @click.stop="menuItemClicked(subItem)">
                        <span class="nopads" :class="{'text-disabled' : subItem.disabled}">
                              {{ subItem.text }}
                        </span>
                    </b-dropdown-item>
                </b-dropdown>
                <b-button
                    v-else
                    size="sm"
                    class="popup-menu-item"
                    @click.stop="menuItemClicked(item)">
                    {{ item.text }}
                </b-button>
            </div>
        </b-button-group>
    </div>
</template>

<script>
import resize from 'vue-resize-directive'
import MapInfoTable from './MapInfoTable'
import MapMarkers from './MapMarkerStore'
import Vue from 'vue'
import {mapHelper, userTracking} from '../mixins/MapMixin'
import L from 'leaflet'
import {LControlZoom, LMap} from 'vue2-leaflet'
import "leaflet-polylinedecorator"
import {restApi} from '../mixins/RestApiMixin';
import 'leaflet-simple-map-screenshoter';
import 'leaflet-wms-header';
import 'leaflet.path.drag'

export default {
    name: 'LeafletMap',
    components: { LMap, MapInfoTable, LControlZoom},
    directives: {
        resize
    },
    mixins: [mapHelper, restApi, userTracking],
    props: {
        menuItems: {
            type: Array,
            default: () => []
        },
        center: {
            type: Object,
            default: null
        },
        findUser: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        isEditing: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        menuEnabled: {
            type: Boolean,
            default: function () {
                return true
            }
        }
    },
    data: function () {
        return {
            map: null,
            dragging: false,
            mapBehavior: null,
            showInfo: false,
            contextMenuVisible: false,
            layerControlVisible: true,
            zoomVisible: true,
            currentCoordinates: null,
            contextCoordinates: null,
            clusterPolylinesVisible: false,
            polylineClusterZoomLevel: 11,
            infoOnTop: false,
            infoFields: null,
            markerStore: null,
            infoContent: [],
            polygons: [],
            polygonLines: [],
            videoRoutes: [],
            polylines: [],
            polylineDecorators: [],
            polylineMapPoints: [],
            polylineBubbles: [],
            vehicleInfos: [],
            polygonLabels: [],
            markers: [],
            endCircles: [],
            addLineEndIndicators: false,
            importedObjects: L.layerGroup(),
            geoLabels: [],
            equipmentGroup: null,
            zoomLevel: 0,
            observationGroup: new L.markerClusterGroup({
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                disableClusteringAtZoom: 15,
                iconCreateFunction: function (cluster) {
                    return L.divIcon({  html: cluster.getChildCount() , className: 'cluster-observation general-cluster', iconSize: L.point(40, 40, true) });
                },
            }),
            storageGroup: new L.markerClusterGroup({
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                disableClusteringAtZoom: 15,
                iconCreateFunction: function (cluster) {
                    return L.divIcon({  html: cluster.getChildCount() , className: 'cluster-storage general-cluster', iconSize: L.point(40, 40) });
                },
            }),
            workAssignmentGroup: new L.markerClusterGroup({
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                disableClusteringAtZoom: 15,
                iconCreateFunction: function (cluster) {
                    return L.divIcon({  html: cluster.getChildCount() , className: 'cluster-workAssignment general-cluster', iconSize: L.point(40, 40) });
                },
            }),
            streetLightsGroup: new L.markerClusterGroup({
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                disableClusteringAtZoom: 15,
                iconCreateFunction: function (cluster) {
                    return L.divIcon({  html: cluster.getChildCount() , className: 'cluster-streetLight general-cluster', iconSize: L.point(40, 40) });
                },
            }),
            powerStationsGroup: new L.markerClusterGroup({
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                disableClusteringAtZoom: 15,
                iconCreateFunction: function (cluster) {
                    return L.divIcon({  html: cluster.getChildCount() , className: 'cluster-powerStation general-cluster', iconSize: L.point(40, 40) });
                },
            }),
            trafficSignGroup: new L.markerClusterGroup({
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                disableClusteringAtZoom: 15,
                iconCreateFunction: function (cluster) {
                    return L.divIcon({  html: cluster.getChildCount() , className: 'cluster-trafficSign general-cluster', iconSize: L.point(40, 40) });
                },
            }),
            userMarkerId: 1,
            anchorPointsGroup: null,
            editablePolygonLineStrings: null,
            editablePolylineStrings: null,
            topMenu: '0px',
            leftMenu: '0px',
            timer: null,
            cursorX: 0,
            cursorY: 0,
            mapCenter: {lat: 62.244207, lon: 25.748005},
            zoom: 12,
            prevZoom: null,
            prevMapCenter: null,
            opacityValue: 10,
            minOpacity: 1,
            maxOpacity: 10,
            selectedOpacities: this.$store.state.mapSettings.leaflet && this.$store.state.mapSettings.leaflet.baseMapOpacityValues || [],
            blackIcon: null,
            layerList: this.$store.state.mapSettings.leaflet && this.$store.state.mapSettings.leaflet.overlayList || [],
            baseMap: this.$store.state.mapSettings.leaflet && this.$store.state.mapSettings.leaflet.baseMap || 'Peruskartta',
            baseUrl: 'https://geodata.routaapi.com/ows?routa_env='+this.$store.state.mapEnv+'&map_key=' + this.$store.state.mapKey,
            layerReferences: [],
            layers: [
                {
                    name: 'Peruskartta',
                    visible: false,
                    format: 'image/png',
                    url: 'https://tiles.bitcomp.com/geodata/common/ows',
                    layers: 'mml_taustakarttarasteri',
                    transparent: true,
                    opacity: 1,
                    defaultOpacity: 1,
                    maxNativeZoom: 17,
                    type: "base"
                },
                {
                    name: 'Maastokartta/ yhdistetty - MML',
                    visible: false,
                    format: 'image/png',
                    url: 'https://tiles.bitcomp.com/geodata/common/ows',
                    layers: 'mml_taustakarttayhdistelma',
                    transparent: true,
                    opacity: 1,
                    defaultOpacity: 1,
                    maxNativeZoom: 17,
                    type: "base"
                },
                {
                    name: 'Maastokartta - MML',
                    visible: false,
                    format: 'image/png',
                    url: 'https://tiles.bitcomp.com/geodata/common/ows',
                    layers: 'mml_maastokarttarasteri',
                    transparent: true,
                    opacity: 0.3,
                    defaultOpacity: 0.3,
                    maxNativeZoom: 17,
                    type: "base"
                },
                {
                    name: 'Ortoilmakuvat - MML',
                    visible: false,
                    format: 'image/png',
                    url: 'https://tiles.bitcomp.com/geodata/common/ows',
                    layers: 'mml_orto_rgb',
                    transparent: true,
                    opacity: 1,
                    defaultOpacity: 1,
                    maxNativeZoom: 17,
                    type: "base"
                },
                {
                     name: 'Vinovalo - MML',
                     visible: false,
                     format: 'image/png',
                     url: 'https://tiles.bitcomp.com/geodata/common/ows',
                     layers: 'mml_hillshade',
                     transparent: true,
                     opacity: 0.3,
                     maxNativeZoom: 17,
                     type: "overlay",
                 },
                {
                    name: 'Kiinteistörajat - MML',
                    visible: false,
                    format: 'image/png',
                    url: 'https://tiles.bitcomp.com/geodata/common/ows',
                    layers: 'mml_parcel_polygon',
                    transparent: true,
                    type: "overlay",
                    opacity: 1,
                    maxNativeZoom: 17
                },
                {
                    name: 'Kiinteistötunnus - MML',
                    visible: false,
                    format: 'image/png',
                    url: 'https://tiles.bitcomp.com/geodata/common/ows',
                    layers: 'mml_parcel_reference_point',
                    transparent: true,
                    type: "overlay",
                    opacity: 1,
                    maxNativeZoom: 17
                },
                /*
                {
                    name: 'Metsäautotiet - omatoiminen ylläpito',
                    visible: false,
                    format: 'image/png',
                    url: 'https://tiles.bitcomp.com/geodata/common/ows',
                    layers: 'routa_mh_jatkuvayllapito_alueurakointi',
                    transparent: true,
                    type: "overlay",
                    opacity: 1,
                    maxNativeZoom: 15
                },
                {
                    name: 'Metsäautotiet - Hall.luokitus',
                    visible: false,
                    format: 'image/png',
                    url: 'https://tiles.bitcomp.com/geodata/common/ows',
                    layers: 'routa_mh_tiet_tiimeittain',
                    transparent: true,
                    type: "overlay",
                    opacity: 1,
                    maxNativeZoom: 15
                },
                {
                    name: 'Metsäautotiet - Kantavuus',
                    visible: false,
                    url: 'https://tiles.bitcomp.com/geodata/common/ows',
                    format: 'image/png',
                    layers: 'routa_mh_tiet_kantavuus_alueittain',
                    transparent: true,
                    type: "overlay",
                    opacity: 1,
                    maxNativeZoom: 15
                }
                */
            ],
            attribution: 'Sitowise & Maanmittauslaitos',
            mapOptions: {
                zoomSnap: 0.5,
                zoomControl: false
            },
            showMap: true,
            simpleScrenshooter: null,

            accessToken: ''
        }
    },

    computed: {
        clusteredPolylines() {
            return [this.OBSERVATION, this.WORK_ASSIGNMENT]
        }
    },

    created() {
        this.accessToken = process.env.VUE_APP_GEODATA_TOKEN
    },

    mounted() {
        var ComponentClass = Vue.extend(MapMarkers)
        this.markerStore = new ComponentClass()
        this.markerStore.$mount()
        this.initMap()
        //Required for small maps
        this.zoomLevel = this.map.getZoom()
        if (this.center) {
            if (this.center.x) {
              this.center.lat = this.center.y
              this.center.lon = this.center.x
            }
            this.mapCenter = this.center
        }
        this.$nextTick(() => {
            this.showUserPosition()
        })
    },

    watch: {
        center: function () {
            if (this.center) {
                this.mapCenter = this.center
                if(this.mapCenter.y && this.mapCenter.x) {
                    this.zoomToPosition(this.mapCenter.y, this.mapCenter.x)
                } else if(this.mapCenter.lat && this.mapCenter.lng) {
                    this.zoomToPosition(this.mapCenter.lat, this.mapCenter.lng)
                }
            }
        },

        zoomLevel: function (value) {
            if (this.clusterPolylinesVisible && value < this.polylineClusterZoomLevel) {
                this.clusterPolylinesVisible = false
                this.handleClusterLines(this.clusterPolylinesVisible)
            } else if (!this.clusterPolylinesVisible && value >= this.polylineClusterZoomLevel) {
                this.clusterPolylinesVisible = true
                this.handleClusterLines(this.clusterPolylinesVisible)
            }
        },

      opacityValue() {
        let layer = this.layerReferences[this.baseMap]
        if (layer) {
            let foundOpacityIndex = this.selectedOpacities.findIndex(item => item.name === layer.name)
            if(foundOpacityIndex !== -1) {
                this.selectedOpacities.splice(foundOpacityIndex, 1)
            }
            if (layer.defaultOpacity !== this.opacityValue/10) {
                this.selectedOpacities.push({name: layer.name, value: this.opacityValue/10})
                this.updateMapSettings({ leaflet: { baseMapOpacityValues: this.selectedOpacities } })
                this.saveMapSettings()
            }
            layer.setOpacity(this.opacityValue/10)
        }
      }
    },

    methods: {
        getMarkerStore() {
            return this.markerStore
        },

        handleClusterLines(show) {
            this.clusteredPolylines.forEach((polyline) => {
                if (this.polylines[polyline]) {
                    this.polylines[polyline].forEach(polyline1 => {
                        if (show) {
                            polyline1.addTo(this.map)
                        } else {
                            polyline1.remove()
                        }
                    })
                }
            })
        },

        initMap: function () {
            this.map = this.$refs['lMap'].mapObject;
            // // Long press is generated also on dragging - need to find other way for context menu in mobile devices
            // this.map.addEventListener('longpress', this.mapLongPress)
            // // Set terrain map
            // this.map.setBaseLayer(defaultLayers.terrain.map)
            this.map.addEventListener('mousemove', this.pointerMove)
            this.map.addEventListener('click', this.mapClicked)
            this.mapMoveEnd()
            this.checkStoredBaseMap()
            this.checkStoredOverlays()
            this.updateOpacityValue()
            this.simpleScrenshooter = L.simpleMapScreenshoter(this.leafletScreenshotOptions).addTo(this.map)
            this.addLayers()
            this.clusterLayers()
        },

        addLayers() {
            const layerControl = L.control.layers(null, null, {
                position: 'bottomleft' // Set the position to bottom left
            }).addTo(this.map);
            this.layers.forEach((layer) => {
                let wmsLayer = L.TileLayer.wmsHeader(
                    layer.url,
                    {
                        layers: layer.layers,
                        format: layer.format,
                        transparent: layer.transparent,
                        visible: layer.visible,
                        opacity: layer.opacity,
                        maxZoom: 20,
                        maxNativeZoom: layer.maxNativeZoom
                    }, [
                        { header: 'Authorization', value: 'Basic ' + this.accessToken },
                    ],
                    null
                )

                if (layer.type === "base") {
                    layerControl.addBaseLayer(wmsLayer, layer.name);
                } else {
                    layerControl.addOverlay(wmsLayer, layer.name);
                }
                if (layer.visible) {
                    wmsLayer.addTo(this.map)
                }
                this.layerReferences[layer.name] = wmsLayer;

                    /*
                    const wmsLayer = L.tileLayer.wms(layer.url, {
                        layers: layer.layers,
                        format: 'image/png',
                        transparent: true,
                        version: '1.1.1',
                        attribution: layer.name
                    }).addTo(this.map);
                    // Add Authorization header
                    wmsLayer.addWmsHeader('Authorization', `Bearer ${this.accessToken}`);

                     */
            });
        },

        clusterLayers() {
            this.map.addLayer(this.observationGroup);
            this.map.addLayer(this.workAssignmentGroup);
            this.map.addLayer(this.storageGroup);
            this.map.addLayer(this.streetLightsGroup);
            this.map.addLayer(this.powerStationsGroup);
            this.map.addLayer(this.trafficSignGroup);
        },

        layerReady: function(layer){
            layer.addEventListener('tileerror', this.reloadTile)
        },

        reloadTile: function (error) {
            error.tile.failures = error.tile.failures !== undefined ? error.tile.failures + 1 : 1;
            setTimeout(
                () => error.tile.src = error.tile.src + "&rnd=" + Math.random(),
                Math.pow(error.tile.failures, 3)
            )
        },

        checkStoredBaseMap: function () {
            let baseMap = this.layers.find(item => item.name === this.baseMap)
            if (baseMap) {
                baseMap.visible = true
            } else {
                this.layers.find(item => item.name === 'Peruskartta').visible = true
            }
        },

        checkStoredOverlays: function () {
            this.layers.forEach(layer => {
                if(this.layerList.includes(layer.name) && layer.type === 'overlay') {
                    layer.visible = true
                }
            })
        },

        changeBaseLayer: function (layer) {
            this.updateMapSettings({ leaflet: { overlayList: this.layerList, baseMap: layer.name } })
            this.saveMapSettings()
            this.updateBaseMap(layer)
            this.updateOpacityValue()
        },

        updateOpacityValue: function () {
            let foundOpacity = this.selectedOpacities.find(layer => layer.name === this.baseMap)
            if(foundOpacity){
                this.opacityValue = foundOpacity.value*10
            } else {
                this.opacityValue = this.layers.find(layer => layer.name === this.baseMap).opacity*10
            }
        },

        updateBaseMap: function(layer) {
            this.baseMap = layer.name
        },

        overlayAdd: function(layer) {
            this.layerList.push(layer.name)
            this.updateMapSettings({ leaflet: { overlayList: this.layerList } })
            this.saveMapSettings()
        },

        overlayRemove: function(layer) {
            this.layerList = this.layerList.filter(layerName => layerName !== layer.name)
            this.updateMapSettings({ leaflet: { overlayList: this.layerList } })
            this.saveMapSettings()
        },

        getMapCenter: function () {
            return this.map.getCenter();
        },

        getViewBounds() {
            return this.map.getBounds()
        },

        getMapZoomLevel: function () {
            this.zoomLevel = this.map.getZoom()
            return this.map.getZoom()
        },

        mousemove: function (event) {
            this.cursorX = event.clientX
            this.cursorY = event.clientY
        },
        touchmove: function (event) {
            this.cursorX = event.touches[0].clientX
            this.cursorY = event.touches[0].clientY
        },
        mapMoveEnd() {
            this.map.on('moveend', (event) => {
                const newMapCenter = event.target.getCenter();
                const newZoomLevel = event.target.getZoom();
                if (newZoomLevel !== this.prevZoom) {
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        this.$emit('onZoomLevelChange', newZoomLevel);
                    }, 500);
                } else if (newMapCenter.lat !== this.prevMapCenter.lat || newMapCenter.lng !== this.prevMapCenter.lng) {
                    const bbox = this.getViewBoundsCoordinates()
                    this.$emit('onBoundingBoxChange', bbox)
                }
                this.prevMapCenter = newMapCenter
                this.prevZoom = newZoomLevel;
            });
        },
        mapClicked: function (evt) {
            if (this.contextMenuVisible) {
                this.contextMenuVisible = false
            } else {
                this.$emit('onMapClicked', evt.latlng)
            }
        },

        // mapLongPress: function (evt) {
        //     if (this.contextMenuVisible) {
        //         this.contextMenuVisible = false
        //     } else {
        //         this.currentCoordinates = evt.latlng
        //         this.openMenuXY(this.cursorX, this.cursorY)
        //     }
        // },

        openMenu: function (event) {
            if (!this.dragging) {
                this.openMenuXY(event.x, event.y)
                event.preventDefault()
            }
        },

        openMenuXY: function (x, y) {
            if (this.menuEnabled) {
                this.contextCoordinates = this.currentCoordinates
                this.contextMenuVisible = true
                Vue.nextTick(function () {
                    this.$refs['map-canvas'].focus()
                    this.showContextMenu(y, x)
                }.bind(this))
            }
        },

        showContextMenu: function (top, left) {
            let largestHeight = window.innerHeight - 240
            let largestWidth = window.innerWidth - 260
            if (top > largestHeight) {
                top = largestHeight
            }
            if (left > largestWidth) {
                left = largestWidth
            }
            this.topMenu = top + 'px'
            this.leftMenu = left + 'px'
        },

        pointerMove: function (event) {
            if(this.currentCoordinates !== event.latlng) {
                this.$emit('pointerCoordinateMove', event.latlng);
            }
            this.currentCoordinates = event.latlng
        },

        hideContextMenu: function () {
            this.contextMenuVisible = false
        },

        copyCoordinates: function () {
            this.$clipboard(this.contextCoordinates.lat + ' ' + this.contextCoordinates.lng)
            this.hideContextMenu()
        },
        menuItemClicked(item) {
            if (!item.disabled) {
                item.onClick(this.contextCoordinates)
                this.hideContextMenu()
            }
        },

        zoomToPosition: function (lat, lon, zoom) {
            this.map.invalidateSize()
            this.map.setView(new L.LatLng(lat, lon), zoom)
        },

      /**
       * Zooms the view to show all the objects in the group
       * @param groupId to specify a group to add the map object to
       **/
      zoomToGroup: function(type) {
        if(this.markers[type] != null) {
          let markers = []
          let keys = Object.keys(this.markers[type])
          for (var i = 0; i < keys.length; i++) {
            markers.push(this.markers[type][keys[i]])
          }
          let group = new L.featureGroup(markers);
          this.map.fitBounds(group.getBounds());
        } else if (this.polylines[type] != null) {
            let lines = []
            let keys = Object.keys(this.polylines[type])
            for (i = 0; i < keys.length; i++) {
                lines.push(this.polylines[type][keys[i]])
            }
            let group = new L.featureGroup(lines);
            this.map.fitBounds(group.getBounds());
        }
      },

        resizeMap: function () {
            this.map.invalidateSize()
        },

        setMapZoomLevel: function (zoom) {
            this.zoom = zoom
            this.map.setZoom(zoom)
        },

        showUserPosition: function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(pos => {
                  if (this.map) {
                    this.showPosition(pos, true, this.watchUserPositionId != null)
                  }
                }, () => {
                }, {timeout: 30000, enableHighAccuracy: true})
            }
        },

        /** Zoom to user location and show user position marker */
        showPosition: function (position, removeMarker = true, zoomToPos = false) {
            if (position) {
                const { latitude, longitude } = position.coords;
                if (zoomToPos) {
                    this.zoomToPosition(latitude, longitude)
                }
                if (removeMarker) {
                    this.showUserMarker(latitude, longitude)
                }
            }
        },

        showUserMarker: function (lat, lon) {
            if (!lat || !lon) return
            this.removeMapMarker(this.userMarkerId, this.USER_LOCATION)
            this.showMapMarker(this.userMarkerId, this.USER_LOCATION, lat, lon, this.markerStore.getUserMarkerIcon())
        },

        addEquipmentMarkers: function (equipmentList) {
            this.equipmentGroup = L.layerGroup();
            this.equipmentGroup.addTo(this.map);
            equipmentList.forEach(function (equipment) {
                this.removeMapMarker(equipment.id, this.EQUIPMENT)
                this.equipmentMarkerInfo(equipment, this.markerStore.getSimpleEquipmentIcon());
            }.bind(this))
        },

        addEquipmentMarker: function (equipment) {
            this.showMapMarker(equipment.id, this.EQUIPMENT, equipment.location.y, equipment.location.x,
                    this.markerStore.getSimpleEquipmentIcon(), false)
            this.zoomToPosition(equipment.location.y, equipment.location.x, 16)
        },

        addDraggableEquipmentMarker: function (equipment) {
            this.showMapMarker(equipment.id, this.EQUIPMENT, equipment.location.y, equipment.location.x,
                    this.markerStore.getSimpleEquipmentIcon(), true)
            this.zoomToPosition(equipment.location.y, equipment.location.x, 18)
        },

        equipmentMarkerInfo: function (equipment, iconElement) {
            let icon = L.divIcon({
                html: iconElement,
                className: 'dummy'
            })
            let marker = L.marker([equipment.location.y, equipment.location.x], {
                zIndexOffset: 5,
                icon: icon,
            })
            this.equipmentGroup.addLayer(marker)
            let manufacturer = equipment.manufacturer ? equipment.manufacturer : '-'
            let description = equipment.description ? equipment.description : '-'
            marker.bindPopup(
                    '<div>' +
                    '<div class="leaflet-bubble-text">' + equipment.name + '</div>' +
                    '<div><div class="col-sm-12  nopads leaflet-bubble-title">' + this.$t('equipment.manufacturer') + ':</div><div class="col-sm-12 leaflet-bubble-content-text nopads">' + manufacturer + '</div></div>' +
                    '<div><div class="col-sm-12  nopads leaflet-bubble-title">' + this.$t('equipment.description') + ':</div><div class="col-sm-12 leaflet-bubble-content-text nopads">' + description + '</div></div>' +
                    '</div>'
            )
        },

        /**
         * Draws a polyline on the map. The Id given needs to be unique,
         * e.g. from database or some random for others
         * @param id a unique id for the polyline
         * @param points coordinates for the line in format [{x: 25, y: 62}]
         * @param color of the line
         * @param dash use dashed line
         * @param type integer that refers to the group of polylines, used e.g. in toggling visibility
         **/
        drawPolyLine: function (id, type, points, color, dash = false, arrows = false, lineWidth = 4,
                                transparency = 1, lineStartPoint = false, closed = false, externalId = null, pointHover = false) {
            if (this.map) {
                // first remove old if exists
                this.removePolyline(id, type)
                if (!this.polylines[type]) {
                    this.polylines[type] = []
                }
                let dump = [dash, arrows, lineWidth, transparency];
                dump [0];
                if (points && points.length > 0) {
                    let mappoints = points.map((point) => {
                        return [point.y, point.x]
                    });
                    let polyline = L.polyline(mappoints, {
                        color: color,
                        weight: lineWidth,
                        opacity: transparency,
                        dashArray: dash ? Math.max(4, lineWidth+4) : null,
                    });
                    polyline.lineData = externalId ? {id: externalId, type: type} : {id: id, type: type}
                    polyline.on('mouseover', this.polylineHoverEvent);
                    polyline.on('mouseout', this.polylineHoverExitEvent);
                    polyline.on('click', this.generalTapEvent);
                    if (!this.clusteredPolylines.includes(type) || this.zoomLevel >= this.polylineClusterZoomLevel) {
                        polyline.addTo(this.map);
                    }
                    if (lineStartPoint) {
                        this.showPolylineStartPoint(id, type, points, closed)
                    }
                    this.polylines[type][id] = polyline;
                    if (pointHover) {
                        this.addPointHovering(points)
                    }
                }
            }
        },

        addPointHovering(points) {
            points.forEach((point, index) => {
                // Create a transparent icon for the marker
                let invisibleIcon = L.icon({
                    iconUrl: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"></svg>',
                    iconSize: [15, 15], // Width and height of the icon
                    iconAnchor: [7.5, 7.5], // Center the icon
                    popupAnchor: [0, -7.5], // Adjust popup if needed
                    className: 'invisible-marker' // Optional class for CSS styling
                });

                // Create a marker with the invisible icon
                let marker = L.marker([point.y, point.x], {
                    icon: invisibleIcon
                });

                // Set marker data for interaction
                marker.pointData = { pointPosition: index };

                // Add hover event listeners (pointerenter and pointerleave)
                marker.on('mouseover', (e) => {
                    this.pointHoverEvent(e, marker.pointData);
                });
                marker.on('mouseout', (e) => {
                    this.pointHoverExitEvent(e, marker.pointData);
                });

                // Add the marker to the Leaflet map
                marker.addTo(this.map);
            });
        },

        showPolylineStartPoint: function (id, type, points, closed = false) {
            if (!this.polylineMapPoints[type]) {
                this.polylineMapPoints[type] = []
            }
            if (!this.polylineMapPoints[type][id]) {
                this.polylineMapPoints[type][id] = []
            }
            if (points) {
                let icon = L.divIcon({
                    html: closed ? this.markerStore.getPolylinePointClosedIcon() : this.markerStore.getPolylinePointIcon(),
                    className: 'dummy'
                });
                let marker = L.marker([points[0].y, points[0].x], {
                    zIndexOffset: 5,
                    icon: icon,
                    draggable: false,
                    autoPan: false
                })
                if (type === this.OBSERVATION) {
                    marker.addTo(this.observationGroup)
                } else {
                marker.addTo(this.map);
                }
                marker.markerData = {id: id, type: type}
                marker.addEventListener('mouseover', this.markerPointerEnterEvent)
                marker.addEventListener('click', this.markerTapEvent)
                this.polylineMapPoints[type][id] = marker
            }
        },

        removePolylinePointsByType: function (type) {
            if (type && this.polylineMapPoints[type]) {
                this.polylineMapPoints[type].forEach(item => item.remove())
                this.polylineMapPoints[type] = []
            }
        },


        removePolylinePoints: function (type, id) {
            if (type && this.polylineMapPoints[type] && id && this.polylineMapPoints[type[id]]) {
                this.polylineMapPoints[type][id].remove()
                this.polylineMapPoints[type][id] = []
            }
        },

        hidePolylineBubble: function (id, type) {
            if (this.polylineBubbles[type] && this.polylineBubbles[type][id]) {
                this.map.removeLayer(this.polylineBubbles[type][id])
                this.polylineBubbles[type][id] = undefined
            }
        },

        showPolylineBubble: function (id, type, posY, posX, title, values) {
            if (!this.polylineBubbles[type] || !this.polylineBubbles[type][id] || !this.polylineBubbles[type][id].isOpen()) {
                if (!this.polylineBubbles[type]) {
                    this.polylineBubbles[type] = []
                }
                this.polylineBubbles[type][id] = null
                let content = '<div>' + '<div class="leaflet-bubble-text">' + title + '</div>'
                values.forEach(item => {
                    content += '<div><div class="col-sm-12  nopads leaflet-bubble-title">' + item.name + ':</div><div class="col-sm-12 leaflet-bubble-content-text nopads" style="color: #404041">' + item.value + '</div></div>'
                })
                content += '</div>'
                var popup = L.popup({closeButton: true, autoPan: false, closeOnClick: false});
                popup.setLatLng(L.latLng(posY, posX));
                popup.setContent(content);
                this.map.addLayer(popup);
                this.polylineBubbles[type][id] = popup
                // popup.openOn(this.map);
            }
        },


        /**
         * Removes a polyline from the map
         * @param id of the line to be removed
         * @param type
         **/
        removePolyline: function (id, type) {
            if (this.polylines[type]) {
                var polyline = this.polylines[type][id]
                if (polyline) {
                    polyline.remove(this.map)
                    this.polylines[type][id] = undefined
                }
            }
            if (this.polylines[id] !== undefined) {
                this.polylines[id].remove()
                this.polylines[id] = undefined
            }
            this.removePolylinePoints(type, id)
        },

        removePolylinesByType: function (type) {
            if (type && this.polylines[type]) {
                this.polylines[type].forEach(function (polyline) {
                    polyline.remove(this.map)
                }, this)
                this.polylines[type] = []
            }
            this.removePolylineStartPointsByType(type)
        },

        removePolylineStartPointsByType: function (type) {
            if (this.polylineMapPoints[type]) {
                this.polylineMapPoints[type].forEach(function (point) {
                    point.remove()
                }, this)
                this.clearClusterByType(type)
                this.polylineMapPoints[type] = []
            }
        },

        removePolygonsByType: function (type) {
            if (type && this.polygons[type]) {
                this.polygons[type].forEach(function (polygon) {
                    polygon.remove(this.map)
                }, this)
                this.polygons[type] = []
            }
        },

        removeAllLineEndIndicators() {
            if (this.endCircles && this.endCircles.length > 0) {
                for (let i = 0; i < this.endCircles.length; i++) {
                    this.map.removeLayer(this.endCircles[i]);
                }
                this.endCircles = [];
            }
        },

        addPolylineEndIndicators(geoJSONFeature, color) {
            // Get the coordinates of the LineString feature
            const pointsArray = geoJSONFeature.geometry.coordinates;

            // Define the center of the circles
            const startCenter = { lat: pointsArray[0][1], lng: pointsArray[0][0] };
            const endCenter = { lat: pointsArray[pointsArray.length - 1][1], lng: pointsArray[pointsArray.length - 1][0] };

            // Create a new circle object for each endpoint
            let solidCircleIcon = this.markerStore.getSolidCircleIcon()
            let iconDiv = this.markerStore.getAsDivElement(solidCircleIcon)
            this.markerStore.setIconStyleProperty(iconDiv, 'color', color)
            // iconDiv.style.color = color
            solidCircleIcon = iconDiv.outerHTML

            let icon = L.divIcon({
                html: solidCircleIcon,
                className: 'dummy'
            });
            let startCircle = L.marker(startCenter, {
                zIndexOffset: 5,
                icon: icon,
                draggable: false,
                autoPan: false
            })

            let endCircle = L.marker(endCenter, {
                zIndexOffset: 5,
                icon: icon,
                draggable: false,
                autoPan: false
            })

            // Add the circles to the map
            this.endCircles.push(startCircle);
            this.endCircles.push(endCircle);
            startCircle.addTo(this.map);
            endCircle.addTo(this.map);
        },

        removeMapItemsByType: function (type) {
            this.removePolylinesByType(type)
            this.removePolygonsByType(type)
            this.removeMapMarkerByType(type)
            this.removePolylinePointsByType(type)
        },

        /**
         * Emits a hover event with the id of the hovered polyline
         * @param e contains the data of the polyline, usually id of the polyline
         **/
        polylineHoverEvent: function (e) {
            this.$emit('onPolylineHover', e.target.lineData)
        },

        /**
         * Emits a hover exit event
         **/
        polylineHoverExitEvent: function (e) {
            this.$emit('onPolylineHoverExit', e.target.lineData)
        },

        pointHoverEvent: function (e) {
            let pointData = e.target.pointData;
            this.$emit('onPointHover', pointData)
        },

        pointHoverExitEvent: function (e) {
            let pointData = e.target.pointData;
            this.$emit('onPointHoverExit', pointData)
        },

        /**
         * Emits a tap event with the id of the tapped polyline
         * @param e contains the data of the polyline, usually id of the polyline
         **/
        polylineTapEvent: function (e) {
            this.$emit('onPolylineTap', e.target.lineData)
        },

        /**
         * Shows map info view (table) on top of the map with given fields and content
         * @param infoFields are the table headers
         * @param infoContent is the data for the table
         **/
        showMapInfo: function (infoFields, infoContent, onTop) {
            this.infoFields = infoFields
            this.infoContent = infoContent
            this.showInfo = true
            this.infoOnTop = onTop
        },

        /**
         * Hides the map info view and sends callback
         **/
        hideMapInfoCallback: function () {
            this.hideMapInfo()
            this.$emit('onMapInfoClosed')
        },

        /**
         * Hides the map info view
         **/
        hideMapInfo: function () {
            this.infoFields = []
            this.infoContent = []
            this.showInfo = false
        },

        /**
         * Creates a new area polygon and ads it on the map
         * @param id is a unique identifier for the polygon, e.g. database id
         * @param lat latitude coordinate
         * @param lng longitude coordinate
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        newPolygon: function (id, lat, lng, type = this.AREA) {
            let polygon = L.polygon([[lat, lng]], {
                color: '#219ade',
                fillColor: '#219ade',
                opacity: 0.5,
                weight: 4
            });
            if(!this.polygons[type]) this.polygons[type] = []
            this.polygons[type][id] = polygon;
            polygon.addTo(this.map);

        },

        /**
         * Adds a point to a polygon. The added point will be the last point.
         * @param id is a unique identifier for the polygon, e.g. database id
         * @param lat latitude coordinate
         * @param lng longitude coordinate
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        addPointToPolygon: function (id, lat, lng, type = this.AREA) {
            if(!this.polygonLines[type]) this.polygonLines[type] = []
            this.polygons[type][id].addLatLng(L.latLng(lat, lng))
        },

        /**
         * Creates a new area polyline and ads it on the map
         * @param id is a unique identifier for the polyline, e.g. database id
         * @param lat latitude coordinate
         * @param lng longitude coordinate
         **/
        newPolyLine: function (id, lat, lng) {
            let polyline = L.polyline([[lat, lng]], {
                color: '#219ade',
                fillColor: '#219ade',
                opacity: 0.5,
                weight: 4
            });
            this.polylines[id] = polyline;
            polyline.addTo(this.map);

        },

        /**
         * Adds a point to a polyline. The added point will be the last point.
         * @param id is a unique identifier for the polyline, e.g. database id
         * @param lat latitude coordinate
         * @param lng longitude coordinate
         **/
        addPointToPolyLine: function (id, lat, lng) {
            this.polylines[id].addLatLng(L.latLng(lat, lng))
        },

        /**
         * Removes the last point from a polygon.
         * @param id is a unique identifier for the polygon, e.g. database id
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        removeLastPointFromPolygon: function (id, type = this.AREA) {
            let polygonPoints = this.polygons[type][id].getLatLngs()[0];
            polygonPoints.pop();
            this.polygons[type][id].setLatLngs(polygonPoints);
        },

        /**
         * Removes the last point from a polyline.
         * @param id is a unique identifier for the polyline, e.g. database id
         **/
        removeLastAddedPointFromPolygon: function (id) {
            this.removeLastPointFromPolygon(id)
        },

        /**
         * Removes the last point from a polyline.
         * @param id is a unique identifier for the polyline, e.g. database id
         **/
        removeLastPointFromPolyline: function (id) {
            let polylinePoints = this.polylines[id].getLatLngs();
            polylinePoints.pop();
            this.polylines[id].setLatLngs(polylinePoints);
        },

        /**
         * Adds a complete polygon to the map
         * @param id is a unique identifier for the polygon, e.g. database id
         * @param type
         * @param points are the coordinates in format { lng, lat } for each point
         * @param stroke
         * @param fill
         * @param transparency
         **/
        drawPolygon: function (id, points, type = this.AREA, stroke = '#1c455b', fill='#219ade', transparency=0.3) {
            let areas = []
            if (!Array.isArray(points[0][0])) {
                // Only one ring
                let ring = []
                points.forEach(point => {
                    ring.push([point[1], point[0]]);
                })
                areas.push(ring)
            } else {
                // Inner rings too
                points.forEach(ring => {
                    let ringPoints = []
                    ring.forEach(point => {
                        ringPoints.push([point[1], point[0]]);
                    })
                    areas.push(ringPoints);
                })
            }
            let polygon = L.polygon(areas, {
                color: stroke,
                fillColor: fill,
                opacity: 1-transparency,
                weight: 2
            });
            polygon.addTo(this.map);
            if(!this.polygons[type]) {
                this.polygons[type] = []
            }
            this.polygons[type][id] = polygon;
        },

        /**
         * Store area lines temporarily
         * @param polygonIndex is a unique identifier for the polygon
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        storeAreaInfo: function (polygonIndex, type = this.AREA) {
            if(!this.polygonLines[type] || !this.polygonLines[type][polygonIndex]){
                // Populate lines from polygon
                this.polygonLines[type] = []
                if (this.polygons[type][polygonIndex]) {
                    let lines = []
                    let polygon = this.polygons[type][polygonIndex]
                    if (polygon) {
                        let rings = polygon.getLatLngs()
                        rings.forEach(ring => {
                            ring.forEach(latLng => lines.push([latLng.lng, latLng.lat]))
                        })
                    }
                    this.polygonLines[type][polygonIndex] = lines
                }
            }
            return this.polygonLines[type][polygonIndex]
        },

        /**
         * Store polyline temporarily
         * @param polylineIndex is a unique identifier for the polygon
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        storeLineInfo: function (polylineIndex, type) {
            let linestring = []
            if(!this.polylines[type]){
                this.polylines[type] = []
            }
            let line = this.polylines[type][polylineIndex]
            if (line) {
                line.eachLatLngAlt(function (lat, lng) {
                    linestring.push([lng, lat])
                })
            }
            return linestring;
        },

        /**
         * Remove feature resize points
         **/
        removeAnchorPoints: function () {
            if (this.anchorPointsGroup !== null) {
                this.anchorPointsGroup.remove();
            }
        },

        /**
         * Add area resize points
         * @param polygonId is a unique identifier for the polygon
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        addAnchorPoints: function (polygonId, type = this.AREA) {
            if (this.anchorPointsGroup !== null) {
                this.anchorPointsGroup.remove();
            }
            // Create a group that can hold map objects
            this.anchorPointsGroup = L.layerGroup();
            // Add the group to the map object
            this.anchorPointsGroup.addTo(this.map);

            this.polygons[type][polygonId].getLatLngs().forEach(((value, index) => {
                value.forEach((latLng, i) => {
                    let marker = L.circleMarker([latLng.lat, latLng.lng], {
                        color: 'red',
                        fillColor: 'white',
                        fillOpacity: 0.8,
                        weight: 3,
                        radius: 6,
                        draggable: true
                    });
                    // Ensure that the marker can receive drag events
                    marker.polygonData = {polygon: polygonId, point: i, layer: index};
                    this.anchorPointsGroup.addLayer(marker);

                    marker.on('drag', function () {
                        this.movePoint(marker.polygonData, marker.getLatLng(), type)
                    }.bind(this))
                });
            }))

        },

        /**
         * Add polyline resize points
         * @param polylineId is a unique identifier for the polyline
         **/
        addPolylineAnchorPoints: function (polylineId) {
            if (this.anchorPointsGroup !== null) {
                this.anchorPointsGroup.remove();
            }
            // Create a group that can hold map objects
            this.anchorPointsGroup = L.layerGroup();
            // Add the group to the map object
            this.anchorPointsGroup.addTo(this.map);
            let icon = L.divIcon({
                className: 'user-marker',
                html: this.markerStore.getDragAnchorMarkerIcon(),
            });
            let headIcon = L.divIcon({
                className: 'user-marker',
                html: this.markerStore.getDragAnchorHeadMarkerIcon(),
            });
            let polylinesLength = this.polylines[polylineId].getLatLngs().length
            this.polylines[polylineId].getLatLngs().forEach((latLng, i) => {
                let marker = L.marker([latLng.lat, latLng.lng], {icon: i + 1 === polylinesLength ? headIcon : icon, draggable: true});
                // Ensure that the marker can receive drag events
                marker.draggable = true
                marker.polylineData = {polyline: polylineId, point: i};
                this.anchorPointsGroup.addLayer(marker);
                marker.on('drag', function (ev) {
                    let position = ev.latlng;
                    this.movePolylinePoint(ev.target.polylineData, position);
                }.bind(this))
            })
        },
        /**
         * Emits a tap event with the id of the tapped area
         * @param ev, clicked area
         **/
        areaTapEvent: function (ev) {
            if (!this.isEditing) {
                let polygonId = -1
                this.polygons.forEach(type => {
                    polygonId = type.findIndex(polygon => polygon === ev.target)
                })
                this.polygons.forEach(type => {
                    type.forEach((polygon, id) => {
                        if (polygon) {
                            if (id === polygonId) {
                                polygon.setStyle({
                                    weight: 8,
                                })
                            } else {
                                polygon.setStyle({
                                    weight: 2
                                })
                            }
                        }
                    })
                })
                this.$emit('editAreaEvent', polygonId)
            }
        },

        /**
         * Store original area lines and set anchor points
         * * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         * @param polygonId is a unique identifier for the polygon
         **/
        editArea: function (polygonId, type = this.AREA) {
            if (!this.isEditing) {
                this.editablePolygonLineStrings = this.storeAreaInfo(polygonId, type)
                this.addAnchorPoints(polygonId, type)
            }
        },

        /**
         * Store original lines and set anchor points
         * @param polylineId is a unique identifier for the polyline
         * @param type geometry category
         **/
        editPolyline: function (polylineId, type = this.OTHER) {
            if (!this.isEditing) {
                this.editablePolylineStrings = this.storeLineInfo(polylineId, type)
                this.addPolylineAnchorPoints(polylineId)
            }
        },

        /**
         * Move area points
         * @param polygonData
         * @param position, point position
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        movePoint: function (polygonData, position, type = this.AREA) {
            this.polygons[type][polygonData.polygon].getLatLngs()[polygonData.layer][polygonData.point] = position;
            this.polygons[type][polygonData.polygon].setLatLngs(this.polygons[type][polygonData.polygon].getLatLngs());
        },

        /**
         * Move polyline points
         * @param polylineData
         * @param position, point position
         **/
        movePolylinePoint: function (polylineData, position) {
            let polylinePoints = this.polylines[polylineData.polyline].getLatLngs();
            polylinePoints[polylineData.point] = position;
            this.polylines[polylineData.polyline].setLatLngs(polylinePoints);
        },

        /**
         *  Disable area editing
         * @param polygonId is a unique identifier for the polygon
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        disableAreaEditing: function (polygonId, type = this.AREA) {
            if (this.anchorPointsGroup !== null) {
                this.anchorPointsGroup.remove();
                this.anchorPointsGroup = null
            }
            this.removePolygon(polygonId, type)
            this.drawPolygon(polygonId, this.editablePolygonLineStrings, type)
            // this.polygons[polygonId].addEventListener('tap', this.areaTapEvent)
            this.editablePolygonLineStrings = null;
        },

        /**
         *  Disable area editing
         * @param polygonId is a unique identifier for the polygon
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        disableLineEditing: function (polylineId, type = this.OTHER) {
            if (this.anchorPointsGroup !== null) {
                this.anchorPointsGroup.remove();
                this.anchorPointsGroup = null
            }
            this.removePolyline(polylineId, type)
            this.drawPolyLine(polylineId, this.editablePolylineStrings, type)
            this.editablePolygonLineStrings = null;
        },

        /**
         * Removes a polygon by id
         * @param id of the polygon to be removed
         * @param type number used for grouping polygons, e.g. in toggling visibility
         */
        removePolygon: function (id, type = this.AREA) {
            if (this.polygons[type] !== undefined) {
                if (this.polygons[type][id] !== undefined) {
                    // Remove polygon if exists
                    this.polygons[type][id].remove()
                    this.polygons[type][id] = undefined
                    // Remove line if exists
                    if(this.polygonLines[type] && this.polygonLines[type][id]) {
                        this.polygonLines[type][id] = undefined
                    }
                    // Remove label if exists
                    if (this.polygonLabels[type] && this.polygonLabels[type][id]) {
                        this.polygonLabels[type][id].remove();
                        this.polygonLabels[type][id] = undefined;
                    }
                }
            }
        },

        /**
         * Adds an info bubble with text for a polygon
         * @param id of the polygon
         * @param label is the text to be shown in the info bubble
         * @param lat coordinate of the bubble anchor
         * @param lng coordinate of the bubble anchor
         * @param type number used for grouping polygons, e.g. in toggling visibility
         */
        addMarkerLabel: function (id, label, lat, lng, type) {
            if (!this.polylineBubbles[type] || !this.polylineBubbles[type][id] || !this.polylineBubbles[type][id].isOpen()) {
                if (!this.polylineBubbles[type]) {
                    this.polylineBubbles[type] = []
                }
                this.polylineBubbles[type][id] = null
                let popup = L.popup({'className': 'popupCustom'})
                    .setLatLng(L.latLng(lat, lng))
                    .setContent('<span class="leaflet-bubble-text">' + label + '</span>')
                this.map.addLayer(popup);
                this.polylineBubbles[type][id] = popup
            }
        },

        hideMarkerLabel: function(id, type) {
            this.hidePolylineBubble(id, type)
        },



        /**
         * Adds an info bubble with text for a vehicle
         * @param id of the polygon
         * @param label is the text to be shown in the info bubble
         * @param lat coordinate of the bubble anchor
         * @param lng coordinate of the bubble anchor
         */
        addVehicleLabel: function (id, label, lat, lng) {
            if (!this.vehicleInfos) {
                this.vehicleInfos = []
            }
            this.hideVehicleLabelIfExists(id)
            let popup = L.popup({'className': 'customPopup', closeButton: false, autoClose: false, closeOnClick: false})
                .setLatLng(L.latLng(lat, lng))
                .setContent('<span class="leaflet-bubble-text">' + label + '</span>')
            this.map.addLayer(popup);
            this.vehicleInfos[id] = popup
        },

        hideVehicleLabelIfExists: function (id) {
            if (this.vehicleInfos) {
                let marker = this.vehicleInfos[id]
                if (marker !== undefined) {
                    this.map.removeLayer(this.vehicleInfos[id])
                    this.vehicleInfos[id] = undefined
                }
            }
        },



        /**
         * Hides an info bubble with text
         * @param id of the polygon
         * @param label is the text to be shown in the info bubble
         * @param lat coordinate of the bubble anchor
         * @param lng coordinate of the bubble anchor
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         */
        addAreaLabel: function (id, label, lat, lng, type = this.AREA) {
            let popup = L.popup({closeButton: true, className: 'popupCustom', autoPan: false, closeOnClick: false});
            popup.setLatLng(L.latLng(lat, lng));
            popup.setContent('<span class="leaflet-bubble-text">' + label + "</span>");
            this.map.addLayer(popup);
            if(!this.polygonLabels[type]) this.polygonLabels[type] = []
            this.polygonLabels[type][id] = popup
        },

        hideAreaLabels(type = this.AREA) {
            if (this.polygonLabels[type]) {
                let labels = this.polygonLabels[type]
                labels.forEach(label => label.remove())
            }
        },

        showInfoBubbleWithoutItem(title, content, lat, lng) {
            let contentCode = '<span class="leaflet-bubble-title">' + title + '</span>'
            if (content) {
                if (content instanceof Object) {
                    contentCode += '<table>'
                    for (var key in content) {
                        contentCode += '<tr><td class="leaflet-bubble-sub-title">' + key + '</td></tr><tr><td class="leaflet-bubble-text">' + content[key] + '</td></tr>'
                    }
                    contentCode += '</table>'
                } else {
                    contentCode += '<span class="leaflet-bubble-text">' + content + '</span>'
                }
            }
            let popup = L.popup({
                closeButton: true,
                autoPan: false,
                closeOnClick: false
            });
            popup.setLatLng(L.latLng(lat, lng));
            popup.setContent(contentCode);
            this.map.addLayer(popup);
        },

        showInfoBubble: function (type, id, title, content, lat, lng) {
            if (this.markers[type]) {
                let marker = this.markers[type][id]
                if (marker) {
                    this.showInfoBubbleWithoutItem(title, content, lat, lng)
                }
            }
        },

        /**
         * Returns the coordinates of a polygon
         * @param id
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        getPolygonBoundaries: function (id, type = this.AREA) {
            return this.polygons[type][id].getLatLngs()
        },
        /**
            * Emits a tap event with the id of the tapped marker
            * @param id of the marker that was tapped
        **/
        markerTapEvent: function (e) {
            this.contextMenuVisible = false
            this.$emit('onMarkerTap', e.target.markerData)
        },

        /**
         * Emits a tap event and determine which map objects were tapped
         **/
        generalTapEvent: async function (e) {
            this.contextMenuVisible = false
            let lineSource = e.sourceTarget ? e.sourceTarget.lineData : null
            const zoom = this.getMapZoomLevel()
            const threshold = zoom < 15 ? 50 : zoom < 17 ? 20 : 10
            var clickPoint = e.latlng; // Get the coordinates where the click occurred
            // Array to store markers found at the clicked point
            const markers = [];
            // Iterate over each layer on the map
            this.map.eachLayer(function(layer) {
                // Check if the layer is a marker and if its coordinates are close to the clicked point
                if (layer instanceof L.Marker && clickPoint.distanceTo(layer.getLatLng()) <= threshold ) {
                    markers.push(layer); // Add the marker to the array
                }
                if (layer instanceof L.Polyline) {
                    // Check if event was triggered by this line click
                    if (lineSource && layer.lineData && lineSource.id === layer.lineData.id && lineSource.type === layer.lineData.type) {
                        // Our item was clicked - add to list
                        markers.push(layer); // Add the layer to the array
                    } else {
                        // Check if there are lines near by
                        const layerPositions = layer.getLatLngs();
                        // Iterate over each point in the polyline
                        for (let i = 0; i < layerPositions.length; i++) {
                            const position = layerPositions[i]
                            if (position instanceof Array) {
                                // TODO - This must be improved for line observations having long distances between points - in this case a click might not be registered
                                let nearBy = position.find(coord => clickPoint.distanceTo(coord) <= threshold)
                                if (nearBy) {
                                    markers.push(layer); // Add the layer to the array
                                }
                            } else {
                                // Calculate the distance from the click point to the polyline point
                                const distance = clickPoint.distanceTo(position);
                                // If the distance is within the threshold and the polyline layer has not been found yet, consider it as a clicked layer
                                if (distance <= threshold) {
                                    markers.push(layer); // Add the layer to the array
                                    break;
                                }
                            }
                        }

                    }
                }
            });
            if (markers.length <= 1) {
                // Emit event for single marker tap
                if(markers[0] instanceof L.Marker) {
                    this.$emit('onMarkerTap', markers[0].markerData);
                } else if(markers[0] instanceof L.Polyline) {
                    this.$emit('onPolylineTap', markers[0].lineData);
                }
            } else {
                const markerData = []
                markers.forEach(marker => {
                    if(marker instanceof L.Marker && marker.markerData) {
                        markerData.push(marker.markerData)
                    } else if(marker instanceof L.Polyline && marker.lineData &&
                        marker.lineData.type !== this.WORK_TRACE &&
                        marker.lineData.type !== this.TRACE
                    ) {
                        markerData.push(marker.lineData)
                    }
                })
                // Remove duplicate entries from markerData (i.e. same item has marker and line)
                const uniqueMarkers = markerData.filter((obj, index, self) =>
                        index === self.findIndex((t) => (
                            t.id === obj.id && t.type === obj.type && t.index === obj.index
                        ))
                )
                if (uniqueMarkers.length === 1) {
                    this.$emit('onMarkerTap', uniqueMarkers[0]); // Emit event for single marker tap
                } else if (uniqueMarkers.length > 1) {
                    this.$emit('onMultiMarkerTap', uniqueMarkers); // Emit event for multiple marker tap
                }
            }
        },

        /**
         * Emits a tap event with the id of the tapped marker
         * @param id of the marker that was tapped
         **/
        markerPointerEnterEvent: function (e) {
            this.$emit('onMarkerPointerEnter', e.target.markerData)
        },

        /**
         * Shows a map marker on the map (or updates if exists)
         * @param id of the marker
         * @param type of the marker (VEHICLE, OBSERVATION, EQUIPMENT, ...)
         * @param lat coordinate for the marker
         * @param lng coordinate for the marker
         * @param iconElement as HTML or SVG code
         * @param draggable boolean
         * @param zIndex
         */
        showMapMarker: function (id, type, lat, lng, iconElement, draggable = false, zIndex = 5,
                                 backgroundShadow = false, externalId = null) {
            if (this.map) {
                this.hideMarkerIfExists(id, type)
                if (!this.markers[type]) {
                    this.markers[type] = []
                }
                let icon
                if(!backgroundShadow){
                    icon = L.divIcon({
                        html: iconElement,
                        shadowSize: [0, 0],
                        className: 'dummy',
                        iconAnchor: [6, 6]
                    });
                }else {
                    icon = L.divIcon({
                        html: iconElement,
                        className: 'user-marker',
                        iconAnchor: [0, 0]
                    });
                }
                let marker = L.marker([lat, lng], {
                    zIndexOffset: zIndex,
                    icon: icon,
                    draggable: draggable,
                    autoPan: draggable
                })

                switch (type) {
                    case this.OBSERVATION:
                        marker.addTo(this.observationGroup);
                        break;
                    case this.WORK_ASSIGNMENT:
                        marker.addTo(this.workAssignmentGroup);
                        break;
                    case this.MATERIAL_STORAGE:
                    case this.MATERIAL_STATION:
                        marker.addTo(this.storageGroup);
                        break;
                    case this.STREET_LIGHT:
                        marker.addTo(this.streetLightsGroup);
                        break;
                    case this.POWER_CENTER:
                        marker.addTo(this.powerStationsGroup);
                        break;
                    case this.TRAFFIC_SIGN:
                        marker.addTo(this.trafficSignGroup);
                        break;
                    default:
                        marker.addTo(this.map)
                        break;
                }
                marker.markerData = externalId ? {id: externalId, type: type} : {id: id, type: type}
                marker.addEventListener('click', this.generalTapEvent)
                marker.addEventListener('mouseover', this.markerPointerEnterEvent)
                if (draggable) {
                    marker.addEventListener('dragend', function (e) {
                        this.dragging = false;
                        this.$emit('onDragEnd', {
                            id:id,
                            position: {
                                x: e.target._latlng.lng,
                                y: e.target._latlng.lat
                            }})
                    }.bind(this))
                    /* This does not work properly in leaflet
                    marker.addEventListener('drag', function (e) {
                        this.dragging = true;
                        this.$emit('onDrag', {
                            id:id,
                            position: {
                                x: e.target._latlng.lng,
                                y: e.target._latlng.lat
                            }})
                    }.bind(this))
                     */
                }
                this.markers[type][id] = marker
            }
        },

        hideMarkerIfExists: function (id, type) {
            if (this.markers[type]) {
                let marker = this.markers[type][id]
                if (marker !== undefined) {
                    marker.remove();
                }
            }
        },

        removeEquipmentGroup: function () {
            if (this.equipmentGroup) this.equipmentGroup.remove();
        },

        removeMapMarker: function (id, type) {
            if (this.markers[type]) {
                let marker = this.markers[type][id]
                if (marker !== undefined) {
                    marker.remove();
                    this.markers[type][id] = undefined
                }
            }
        },

        removeMapMarkerByType: function (type) {
            if (type && this.markers[type]) {
                this.clearClusterByType(type)
                this.markers[type].forEach(function (marker) {
                    marker.remove()
                }, this)
                this.markers[type] = []
            }
        },

        clearClusterByType(type) {
            switch (type) {
                case this.OBSERVATION:
                    this.observationGroup.clearLayers();
                    break;
                case this.MATERIAL_STORAGE:
                case this.MATERIAL_STATION:
                    this.storageGroup.clearLayers();
                    break;
                case this.WORK_ASSIGNMENT:
                    this.workAssignmentGroup.clearLayers();
                    break;
                case this.STREET_LIGHT:
                    this.streetLightsGroup.clearLayers();
                    break;
                case this.POWER_CENTER:
                    this.powerStationsGroup.clearLayers();
                    break;
                case this.TRAFFIC_SIGN:
                    this.trafficSignGroup.clearLayers();
                    break;
                default:
                    break;
            }
        },

        getPolylineLength(id){
            return this.polylines[id].getLatLngs().length
        },

        getPolygonLength(id, type = this.AREA){
            return this.polygons[type][id].getLatLngs()[0].length
        },

        attachEventListenersToAreas: function () {
            this.polygons.forEach(type =>{
                type.forEach(polygon => {
                    if (polygon) {
                        polygon.on('click', this.areaTapEvent)
                    }
                })
            })
        },

        removeAreaEventListeners: function () {
            this.polygons.forEach(type =>{
                type.forEach(polygon => {
                    if (polygon) {
                        polygon.removeEventListener('tap', this.areaTapEvent)
                    }
                })
            })
        },

        /**
         * Show video route
         * @param color, route color
         * @param routeId, video metadata id
         * @param route, route coordinate points
         */
        drawObservationVideoRoute: function (color, routeId, route) {
            if (route.length > 1) {
                let line = route.map((point) => {
                    return [point.lat, point.lon]
                });
                let polyline = L.polyline(line, {
                    color: color,
                    weight: 5
                });
                polyline.lineData = routeId
                polyline.addTo(this.map)
                L.polylineDecorator(polyline, {
                    patterns: [
                        {
                            repeat: 50,
                            symbol: L.Symbol.arrowHead({
                                pixelSize: 5,
                                polygon: false,
                                headAngle: 75,
                                pathOptions: {
                                    stroke: true,
                                    weight: 2,
                                    color: '#FFFFFF'
                                }
                            })
                        }
                    ]
                }).addTo(this.map)
                if (!this.polylines[this.TRACE]) {
                    this.polylines[this.TRACE] = []
                }
                this.polylines[this.TRACE][routeId] = polyline
            }
        },

        drawSimulatedRoute: function (routeId, route) {
            if (route.length > 1) {
                let line = route.map((point) => {
                    return [point.y, point.x]
                });
                let polyline = L.polyline(line, {
                    color: 'grey',
                    weight: 5,
                    opacity: 0.6
                });
                polyline.lineData = routeId
                polyline.addTo(this.map);
                let decorator = L.polylineDecorator(polyline, {
                    patterns: [
                        {
                            repeat: 50,
                            symbol: L.Symbol.arrowHead({
                                pixelSize: 5,
                                polygon: false,
                                headAngle: 75,
                                pathOptions: {
                                    stroke: true,
                                    weight: 2,
                                    color: '#000000'
                                }
                            })
                        }
                    ]
                })
                decorator.addTo(this.map)
                if (!this.polylines[this.TRACE]) {
                    this.polylines[this.TRACE] = []
                }
                if (!this.polylineDecorators[this.TRACE]) {
                    this.polylineDecorators[this.TRACE] = []
                }
                this.polylines[this.TRACE][routeId] = polyline
                this.polylineDecorators[this.TRACE][routeId] = decorator
            }
        },

        hideSimulatedRoute: function (routeId) {
           if (this.polylines[this.TRACE] && this.polylines[this.TRACE][routeId])   {
               this.polylines[this.TRACE][routeId].remove(this.map)
           }
            if (this.polylineDecorators[this.TRACE] && this.polylineDecorators[this.TRACE][routeId])   {
                this.polylineDecorators[this.TRACE][routeId].remove(this.map)
            }
        },

        /**
         * Removes route progress lines from map
         * @param routeId
         */
        removeRouteProgressPolyLine: function (routeId) {
            if (this.videoRoutes[routeId] !== undefined) {
                this.videoRoutes[routeId].remove(this.map)
                this.videoRoutes[routeId] = undefined
            }
        },

        /**
         * Show route progress
         * @param pointIndex, current video time coordinate
         * @param routeId, video metadata id
         * @param videoEnded, Fill rest of the route if coordinates goes over the video
         */
        drawRouteProgressPolyLine: function (pointIndex, routeId, videoEnded = false, color = '#7CFC00') {
            // Clear old route progress line
            if (this.videoRoutes[routeId] !== undefined) {
                this.videoRoutes[routeId].remove(this.map)
                this.videoRoutes[routeId] = undefined
            }

            let line = []
            let point = 0
            if (this.polylines[this.TRACE] && this.polylines[this.TRACE][routeId]) {
                if (videoEnded) {
                    this.polylines[this.TRACE][routeId].getLatLngs().forEach(function (value, index) {
                        point = index
                        line.push(value)
                    })
                } else {
                    this.polylines[this.TRACE][routeId].getLatLngs().forEach(function (value, index) {
                        if (index <= pointIndex) {
                            point = index
                            line.push(value)
                        }
                    })
                }
                // Line string need at least two points
                if (point > 0) {
                    let polyline = L.polyline(line, {
                        color: color,
                        weight: 5,
                        opacity: 0.6
                    });
                    polyline.lineData = routeId
                    polyline.addTo(this.map);
                    this.videoRoutes[routeId] = polyline
                }
            }
        },

        removeRouteProgressPolyLines: function (videos) {
            videos.forEach(videoId => {
                // Clear old route progress line
                if (this.videoRoutes[videoId] !== undefined) {
                    this.videoRoutes[videoId].remove(this.map)
                    this.videoRoutes[videoId] = undefined
                }
            })
        },

        hexToRgb: function (hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        },

        addGeoLabel(lat, lon, text, collectionKey) {
            if (!this.geoLabels[collectionKey]) {
                this.geoLabels[collectionKey] = []
            }
            const icon = L.divIcon({
                html: '',
                className: 'geojson-no-icon',
                iconSize: [0,0],
                iconAnchor: [0,0]
            });
            let marker = new L.marker([lat, lon], {icon: icon})
            marker.bindTooltip(text, {
                permanent: true,
                direction: 'right',
                className: 'geojson-label',
                offset: [0, 0],
                sticky: true
            })
            marker.addTo(this.map)
            this.geoLabels[collectionKey].push(marker)
        },

        removeGeoLabels(collectionKey) {
            if (this.geoLabels[collectionKey]) {
                this.geoLabels[collectionKey].forEach(marker => marker.remove())
            }
            this.geoLabels[collectionKey] = []
        },

        removeSingleGeoLabel(collectionKey, text) {
            if (this.geoLabels[collectionKey][text]) {
                this.geoLabels[collectionKey][text].forEach(marker => marker.remove())
            }
            this.geoLabels[collectionKey][text] = []
        },

        removeLastGeoJsonObject() {
            if (this.importedObjects.getLayers() && this.importedObjects.getLayers().length > 0) {
                this.importedObjects.removeLayer(this.importedObjects.getLayers()[this.importedObjects.getLayers().length-1])
            }
        },

        removeGeoJsonObject(index) {
            if (this.importedObjects.getLayers() && this.importedObjects.getLayers().length > 0) {
                this.importedObjects.removeLayer(this.importedObjects.getLayers()[index])
            }
        },

        handleGeoJsonEvents(feature, layer, color) {
            if (feature && feature.geometry.type === 'LineString' && this.addLineEndIndicators) {
                this.addPolylineEndIndicators(feature, color)
            }
            layer.on('click', function () {
                this.contextMenuVisible = false
                this.$emit('onGeoJsonClicked', feature, layer, feature.properties.id, feature.properties.importedItemIndex)
            }, this);
            layer.on('mouseover', () => {
                this.$emit('onGeoJsonHoverEvent', null, feature.properties, feature.properties.id, feature.properties.importedItemIndex)
            }, this);
            layer.on('mouseout', () => {
                this.$emit('onGeoJsonHoverExitEvent', null, feature.properties, feature.properties.id, feature.properties.importedItemIndex)
            }, this);
        },

        addGeoJsonObjects: function (geoJson, focusOnLayer = true, lineColor = '#B200FF', addLineEndIndicators = false,
                                     transparency = 1, lineWidth = 3){
            this.addLineEndIndicators = addLineEndIndicators
            if (geoJson) {
                let rgb = this.hexToRgb(lineColor)
                lineColor = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + transparency + ')'
                const icon = L.divIcon({
                    className: 'geojson-point-container',
                    html: '<div class="geojson-point" style="background: '+lineColor+'"/>',
                    iconSize: [16, 16],
                    iconAnchor: [8,8]
                });
                // icon selected style
                const iconSelected = L.divIcon({
                    className: 'geojson-point-container',
                    html: '<div class="geojson-point" style="background: #ff0000"/>',
                    iconSize: [16, 16],
                    iconAnchor: [8,8]
                });
                // Show geoJson on map and set map boundaries accordingly
                const geoJsonFeature = L.geoJson(JSON.parse(geoJson), {
                    // set styles by selection
                    pointToLayer: function (feature, latlng){
                        let markerIcon = feature.properties && feature.properties.selected && feature.properties.selected === true ? iconSelected : icon
                        return L.marker(latlng, {icon: markerIcon});
                    },
                    style: function (feature) {
                        return {
                            color: feature.properties && feature.properties.selected && feature.properties.selected === true ? '#ff0000' : lineColor,
                            weight: lineWidth
                        }
                    },
                    // onEachFeature: this.handleGeoJsonEvents
                    onEachFeature: (feature,layer) => {
                        let color = feature.properties && feature.properties.selected && feature.properties.selected === true ? '#ff0000' : lineColor
                        this.handleGeoJsonEvents(feature,layer,color)
                    }
                })
                this.importedObjects.addLayer(geoJsonFeature)
                this.importedObjects.addTo(this.map);
                geoJsonFeature.bringToBack()
                if (geoJsonFeature && focusOnLayer) {
                    this.map.fitBounds(geoJsonFeature.getBounds())
                }
            }
        },

        hideGeoJsonObjects: function () {
            this.importedObjects.eachLayer(function(layer) {
                this.map.removeLayer(layer);
            }.bind(this));
            this.importedObjects = L.layerGroup();
        },

        getViewBoundsCoordinates() {
            const bounds = this.map.getBounds();
            const left = bounds.getSouthWest().lng;
            const bottom = bounds.getSouthWest().lat;
            const right = bounds.getNorthEast().lng;
            const top = bounds.getNorthEast().lat;
            return [left, bottom, right, top];
        },

        getPolylineGeometry(id) {
            let points = this.polylines[id] && this.polylines[id].getLatLngs()
            return points ? points.map(item => [item['lat'], item['lng']]) : null
        },

        getPointGeometry(id, type = undefined) {
            let point = this.markers[type] && this.markers[type][id].getLatLng()
            return point ? [point.lng, point.lat] : null
        },

        getPolygonGeometry(id, type = this.AREA) {
            return this.polygons[type][id].toGeoJSON().geometry.coordinates
        },

        getMapType() {
            return 'LEAFLET'
        },
        hideMapControls() {
            this.$refs.userposControl.style.display = 'none'
            this.$refs.sliderControl.style.display = 'none'
            this.layerControlVisible = false
            this.zoomVisible = false
        }
    }
}
</script>

<style>
.cluster-observation {
    background-color: rgb(255, 190, 68);
    color: black;
}

.cluster-storage {
    background-color: rgb(131, 201, 240);
    color: #fafafa;
}

.cluster-workAssignment {
    background-color: rgb(76, 194, 140);
    color: #fafafa;
}

.cluster-streetLight {
    background-color: rgb(255, 255, 255);
    color: #ffc400;
}

.cluster-powerStation {
    background-color: rgb(35, 35, 35);
    color: #ffc400;
}

.cluster-trafficSign {
    background-color: rgb(189, 58, 28);
    color: #fafafa;
}

.general-cluster {
    font-size: 1.3em;
    text-align: center;
    line-height: 2.5em;
    border: 0.1em solid black;
    box-shadow: 0 0 0.2em black;
    border-radius: 50%;
}

</style>

<style scoped>
.color-white {
    color: #fff;
}
.opacity-slider-container {
    position: absolute;
    bottom: 1em;
    left: calc(50% - 5em);
    width: 10em;
    z-index: 2;
    padding: .1em .5em .1em .5em;
    border-radius: .5em;
    background: rgba(73, 73, 73, 0.44);
}

#right-click-menu li:not(.context-menu-coordinates) {
    cursor: pointer;
}

.popup-menu-item {
    width: 100% !important;
    border-radius: 0;
    text-align: left !important;
    color: #FFFFFF;

}

.popup-sub-menu-item {
    text-align: left;
    font-size: .9em;
}

/deep/ .btn-sm {
    border: none;
    border-radius: 0;
}

/deep/ #right-click-menu li {
    padding: 0;
}

/deep/ .dropdown-menu {
    padding: 0;
}

/deep/ .dropdown-item {
    padding: .25em .5em;
}

</style>
